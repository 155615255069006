import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Dashboard from './Pages/Dashboard';
import Users from './Pages/Users';
import Admin from './Pages/Admin';
import Notification from './Pages/Notification';
import CompanyConfig from './Pages/CompanyConfig';
import PrivateRoute from './Components/PrivateRoute';
import DashboardV2 from './Pages/DashboardV2';
import Layout from './Pages/Layout';

function App() {
  return (
    <Router>
    <Routes>
      {/* Routes without Layout */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      
      {/* Routes with Layout */}
      <Route element={<Layout />}>
        <Route path="/dashboardv2" element={<DashboardV2 />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
        <Route path="/admin" element={<PrivateRoute><Admin /></PrivateRoute>} />
        <Route path="/notification" element={<PrivateRoute><Notification /></PrivateRoute>} />
        <Route path="/company/:companyId" element={<PrivateRoute><CompanyConfig /></PrivateRoute>} />
      </Route>

      {/* Default route */}
      <Route path="/" element={<Navigate replace to="/login" />} />
    </Routes>
  </Router>
  );
}

export default App;
