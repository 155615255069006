import React, { useState, useEffect } from 'react';
import { fetchScriptsForCompany } from '../Services/DataServices';  // Assume this function is correctly implemented to fetch scripts
import AddScript from './AddScript';
import { createScript } from '../Services/CreateServices';
import { updateScripts } from '../Services/UpdateServices';
import { deleteScript } from '../Services/DeleteServices';
import { RefreshCw, UserPlus, PenSquare, Trash2, RotateCw } from 'lucide-react';


const ScriptDetails = ({company}) => {
    const [scripts, setScripts] = useState([]);
    const [editScriptId, setEditScriptId] = useState(null);
    const [editedScripts, setEditedScripts] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [refresh, setRefresh] = useState(false)

    const handleSaveScript = (scriptData) => {
        console.log("Saving script:", scriptData);
        // Add an API call to save the script to the database
        setModalShow(false);  // Close modal on save
        createScript(scriptData)
            .then(r => {
                console.log(r);
                setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
            })
            .catch(e => console.log(e));
    };

    useEffect(() => {
        fetchScriptsForCompany(company.companyID).then(data => {
            setScripts(data);
            const scriptEdits = data.reduce((acc, script) => {
                acc[script.scriptId] = { ...script };
                return acc;
            }, {});
            setEditedScripts(scriptEdits);
        }).catch(e=>console.log(e));
    }, [refresh]);

    const handleEdit = (scriptId) => {
        setEditScriptId(scriptId);
    };

    const handleSave = (scriptId) => {
        // Optionally integrate API call to update the server
        console.log('Saving script', editedScripts[scriptId]);
        updateScripts(scriptId,editedScripts[scriptId])
            .then(r => {
                console.log(r);
                setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
            })
            .catch(e => console.log(e));
        setScripts(scripts.map(script => script.scriptId === scriptId ? { ...editedScripts[scriptId] } : script));
        setEditScriptId(null);
    };

    const handleCancel = (scriptId) => {
        const originalScript = scripts.find(script => script.scriptId === scriptId);
        setEditedScripts(prev => ({ ...prev, [scriptId]: { ...originalScript } }));
        setEditScriptId(null);
    };

    const handleDelete = (scriptId) => {
        // Optionally integrate API call to update the server
        setScripts(scripts.filter(script => script.scriptId !== scriptId));
        deleteScript(scriptId).then(r=> setRefresh(!refresh)).catch(e=>console.log(e))
    };

    const handleChange = (e, scriptId) => {
        const { name, value } = e.target;
        setEditedScripts(prev => ({
            ...prev,
            [scriptId]: { ...prev[scriptId], [name]: value }
        }));
    };

    return (
        <div className="container">
            <h2 className='text-xl font-bold bg-orange-200 text-orange-700 p-1'>Scripts for Company {company.companyName}</h2>
            <p className='text-lg font-bold bg-orange-200 text-orange-700 p-1'>Update and Manage script details</p>
            <button className='bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded flex items-center mt-3 mb-3' onClick={() => setModalShow(true)}>Add Script</button>
            <AddScript
                show={modalShow} 
                onClose={() => setModalShow(false)} 
                onSave={handleSaveScript} 
                company={company}  // Pass the company name to the modal
            />
            <div className="overflow-x-auto">
            <table className="w-full table-auto border-collapse">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-4 py-2 text-left">Script Name</th>
                        <th className="px-4 py-2 text-left">Device Name</th>
                        <th className="px-4 py-2 text-left">Machine Name</th>
                        <th className="px-4 py-2 text-left">Notification Title</th>
                        <th className="px-4 py-2 text-left">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {scripts.map(script => (
                        <tr key={script.scriptId} className="border-b hover:bg-gray-100">
                            {editScriptId === script.scriptId ? (
                                <>
                                    <td className="px-4 py-3">
                                        <input 
                                            type="text" 
                                            name="scriptName" 
                                            value={editedScripts[script.scriptId].scriptName} 
                                            onChange={e => handleChange(e, script.scriptId)} 
                                            className="w-full p-2 border rounded"
                                        />
                                    </td>
                                    <td className="px-4 py-3">
                                        <input 
                                            type="text" 
                                            name="deviceName" 
                                            value={editedScripts[script.scriptId].deviceName} 
                                            onChange={e => handleChange(e, script.scriptId)} 
                                            className="w-full p-2 border rounded"
                                        />
                                    </td>
                                    <td className="px-4 py-3">
                                        <input 
                                            type="text" 
                                            name="machineName" 
                                            value={editedScripts[script.scriptId].machineName} 
                                            onChange={e => handleChange(e, script.scriptId)} 
                                            className="w-full p-2 border rounded"
                                        />
                                    </td>
                                    <td className="px-4 py-3">
                                        <input 
                                            type="text" 
                                            name="notificationTitle" 
                                            value={editedScripts[script.scriptId].notificationTitle} 
                                            onChange={e => handleChange(e, script.scriptId)} 
                                            className="w-full p-2 border rounded"
                                        />
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td className="px-4 py-3">{script.scriptName}</td>
                                    <td className="px-4 py-3">{script.deviceName}</td>
                                    <td className="px-4 py-3">{script.machineName}</td>
                                    <td className="px-4 py-3">{script.notificationTitle}</td>
                                </>
                            )}
                            <td className="px-4 py-3">
                                {editScriptId === script.scriptId ? (
                                    <div className="flex space-x-2">
                                        <button className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 rounded" onClick={() => handleSave(script.scriptId)}>
                                            <Save className="w-4 h-4" />
                                        </button>
                                        <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold p-2 rounded" onClick={() => handleCancel(script.scriptId)}>
                                            <X className="w-4 h-4" />
                                        </button>
                                    </div>
                                ) : (
                                    <div className="flex space-x-2">
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded" onClick={() => handleEdit(script.scriptId)}>
                                            <PenSquare className="w-4 h-4" />
                                        </button>
                                        <button className="bg-red-500 hover:bg-red-700 text-white font-bold p-2 rounded" onClick={() => handleDelete(script.scriptId)}>
                                            <Trash2 className="w-4 h-4" />
                                        </button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </div>
    );
};

export default ScriptDetails;
