import React, { useEffect, useState } from 'react';
import { fetchNotificationsForCompany, fetchScriptsForCompany, getLoggedInUserCompany } from '../Services/DataServices';
import { ScriptNumbers } from './ScriptNumbers';
import { ArrowClockwise } from 'react-bootstrap-icons';

export const NotificationsDD = ({ company: initialCompany }) => {
    const [selectedScript, setSelectedScript] = useState(null);
    const [scripts, setScripts] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        const fetchNotifications = async () => {
            setLoading(true);
            try {
                const companyData = initialCompany || await getLoggedInUserCompany();
                const scriptsData = await fetchScriptsForCompany(companyData.companyID);
                setScripts(scriptsData);
                console.log(scriptsData)
                setError(false);
            } catch (e) {
                setError(true);
                setScripts([]);
            } finally {
                setLoading(false);
            }
        };
        fetchNotifications();
    }, [initialCompany, refresh]);

    const handleSelectChange = (event) => {
        const scriptId = parseInt(event.target.value, 10);
        const selected = scripts.find(script => script.scriptId === scriptId);
        setSelectedScript(selected);
    };

    const refreshComp = () => {
        setRefresh(!refresh);
    }

    if (loading) return <div className=' d-flex justify-content-center mb-3'><span className='loader'></span></div>;
    if (error) return <div className='text-center'>No Scripts found or Somthing went wrong</div>;

    return (
        <div className='container mb-3'>
            <h2 className='text-xl font-bold bg-green-200 text-green-700 p-1'>Update Whatsapp Notification Numbers</h2>
            <p className='text-lg font-bold bg-green-200 text-green-700 p-1'>Select notification and update</p>
            <div className="flex md:flex-nowrap items-center space-x-4 w-full mt-3">
                <label htmlFor="ScriptID" className="text-sm font-medium text-gray-700">
                    Select Notification
                </label>

                <div className="relative w-full md:w-3/4">
                    <select
                        className="block w-full p-2.5 border border-gray-300 rounded-md text-gray-700 bg-white focus:ring-green-500 focus:border-green-500 shadow-default"
                        value={selectedScript?.scriptId || ''}
                        onChange={handleSelectChange}
                    >
                        <option value="">None</option>
                        {scripts.map((script) => (
                            <option key={script.scriptId} value={script.scriptId}>
                                {script.notificationTitle}
                            </option>
                        ))}
                    </select>
                </div>

                <span
                    className="text-blue-500 cursor-pointer hover:text-blue-700 transition ease-in-out duration-200 flex items-center"
                    onClick={refreshComp}
                >
                    Refresh
                    <ArrowClockwise className="ml-1 h-5 w-5 text-blue-500" />
                </span>
            </div>
            {selectedScript && <ScriptNumbers scriptID={selectedScript.scriptId} />}
        </div>
    );
};
