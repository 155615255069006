import React, { useState } from 'react';


const DashboardV2 = () =>{
return(
    <div>
    <h1>This is dashboard!!</h1>
    <h1>This is dashboard!!</h1>
    </div>)
}

export default DashboardV2;