import React, { useEffect, useState } from "react";
import { fetchPhoneNumbersForScript } from "../Services/DataServices";
import { AddPhNumberToScript } from "../Services/CreateServices";
import { deletePhNumber } from "../Services/DeleteServices";
import { ToggleNumberActivation, TurnALLONOFF } from "../Services/UpdateServices";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "../Styles/PhoneNumbers.css";
import { PenSquare, Trash2, Power, PowerOff } from "lucide-react";

export const ScriptNumbers = ({ scriptID }) => {
    const [notiNumbers, setNotiNumbers] = useState([]);
    const [error, setError] = useState("");
    const [newPhoneNumber, setNewPhoneNumber] = useState('');
    const [showAddNumber, setShowAddNumber] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [countries, setCountries] = useState(["in"]);

    useEffect(() => {
        console.log("Use effect called");
        fetchPhoneNumbersForScript(scriptID)
            .then((data) => {
                console.log(data)
                setNotiNumbers(data);
                setError("");  // Clear any previous errors
            })
            .catch((e) => setError(e.message || "Failed to fetch data"));  // Display a more user-friendly error message
    }, [scriptID,refresh]);


    const handleDelete = (notificationId) => {
        // Function to call API to delete the phone number
        console.log(`Delete notification with ID: ${notificationId}`);
        deletePhNumber(notificationId).then(r => {
            console.log(r);
            setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
        })
        .catch(e => console.log(e));
    };

    const toggleActive = (notificationId) => {
        // Function to toggle isActive status and call API to update
        ToggleNumberActivation(notificationId).then(r => {
            console.log(r);
            setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
        })
        .catch(e => console.log(e));
        console.log(`Toggle active status for ID: ${notificationId}`);
    };

    const handleAddNumber = () => {
        console.log(`Add phone number: ${newPhoneNumber} for script ID: ${scriptID}`);
        AddPhNumberToScript({
            scriptID,
            WhatsappNumber: newPhoneNumber
        }).then(r => {
            console.log(r);
            setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
        })
        .catch(e => console.log(e));
        // Here you would typically make an API call to save the new phone number
        // Simulating adding to list
        setNewPhoneNumber('');
        setShowAddNumber(false);
    };

    const handleAllON = () =>{
        TurnALLONOFF(scriptID,"on").then(r => {
            console.log(r);
            setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
        })
        .catch(e => console.log(e));
    }
    const handleAllOFF = () =>{
        TurnALLONOFF(scriptID,"off").then(r => {
            console.log(r);
            setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of companies
        })
        .catch(e => console.log(e));
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <div className="own-flex justify-content-between mt-3 mb-2 pb-2 gap-2">
            <div className="own-flex align-items-center gap-3">    
            <button className={showAddNumber?"btn bg-gray-700 hover:bg-gray-900":"btn bg-indigo-700 text-white hover:bg-indigo-900"} onClick={() => setShowAddNumber(!showAddNumber)}>
                {showAddNumber ? 'Cancel Add' : 'Add Phone Number'}
            </button>
            {showAddNumber && (
                <div className="own-flex gap-3">
                    <PhoneInput
                        country={'in'}
                        value={newPhoneNumber}
                        onChange={(e) => setNewPhoneNumber(e)}
                        onlyCountries={countries}
                    />
                    {/* <input
                        type="text"
                        placeholder="Enter phone number"
                        value={newPhoneNumber}
                        onChange={(e) => setNewPhoneNumber(e.target.value)}
                    /> */}
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 rounded flex items-center" onClick={handleAddNumber}>Save</button>
                </div>
            )}</div>
            <div className="d-flex gap-3 align-items-center">
            <button className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 rounded flex items-center" onClick={handleAllON}>Turn ON All</button>
            <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold p-2 rounded flex items-center" onClick={handleAllOFF}>Turn OFF All</button>
            </div>
            </div>
            <div className="overflow-x-auto">
            <table className="w-full table-auto border-collapse">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-4 py-2 text-left">Phone Number</th>
                        <th className="px-4 py-2 text-left">Active Status</th>
                        <th className="px-4 py-2 text-left">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {notiNumbers.map((notification) => (
                        <tr key={notification.notificationId} className="border-b hover:bg-gray-100">
                            <td className="px-4 py-3">{notification.phoneNumber}</td>
                            <td className="px-4 py-3">
                                <span className={`flex items-center ${notification.isActive ? 'text-green-600' : 'text-gray-500'}`}>
                                    {notification.isActive ? 'ON' : 'OFF'}
                                    <span className={`ml-2 inline-block h-3 w-3 rounded-full ${notification.isActive ? 'bg-green-600' : 'bg-gray-500'}`}></span>
                                </span>
                            </td>
                            <td className="px-4 py-3">
                                <div className="flex space-x-2">
                                    <button 
                                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold p-2 rounded flex items-center"
                                        onClick={() => handleDelete(notification.notificationId)}
                                    >
                                        Delete <Trash2 className="ml-2 w-4 h-4" />
                                    </button>
                                    {notification.isActive ? (
                                        <button 
                                            className="bg-red-500 hover:bg-red-700 text-white font-bold p-2 rounded flex items-center"
                                            onClick={() => toggleActive(notification.notificationId)}
                                        >
                                            Turn OFF <PowerOff className="ml-2 w-4 h-4" />
                                        </button>
                                    ) : (
                                        <button 
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 rounded flex items-center"
                                            onClick={() => toggleActive(notification.notificationId)}
                                        >
                                            Turn ON <Power className="ml-2 w-4 h-4" />
                                        </button>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </div>

    );
};
