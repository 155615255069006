import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Image from "../Assets/Images/techcore3.jpg";
import Logo from "../Assets/Images/TechCoreLogo2.png";
import GoogleSvg from "../Assets/Images/icons8-google.svg";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import "../Styles/login.css"

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    setError('');

    if (!email || !password) {
      setError('Email and password are required');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });
      localStorage.setItem('token', response.data.accessToken); 
      localStorage.setItem('refreshToken', response.data.refreshToken);
      const token = localStorage.getItem('token'); 

      const loggedInResponse = await axios.get(`${process.env.REACT_APP_API_URL}/manage/info`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      });
      localStorage.setItem('email', loggedInResponse.data.email);
      const UserTabs = await axios.get(`${process.env.REACT_APP_API_URL}/api/Account/getUserAccess/${localStorage.getItem('email')}`, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });
      localStorage.setItem('routes', UserTabs.data.join());
      setLoading(false);
      navigate('/dashboard');
    } catch (err) {
      setLoading(false);
      if(err.toString().includes("401")) {
        setError("Incorrect email or password!");
      } else {
        setError('Failed to login: ' + err);
      }
    }
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen">
    {/* Left side - Image (hidden on mobile) */}
    {/* <div className="hidden lg:block w-1/2">
      <img src={Image} alt="Login" className="w-full h-full object-cover" />
    </div> */}
    
    {/* Right side - Login form */}
    <div className="w-full lg:w-full flex flex-col items-center justify-center px-6 py-8 lg:px-16 bg-gray-50">
      {/* Large brand logo */}
      <div className="w-full max-w-md">
        <img src={Logo} alt="Brand Logo" className="w-2/3 h-full" />
      </div>
      
      <div className="w-full max-w-md">
        <h2 className="text-3xl font-bold mb-2">Welcome back!</h2>
        <p className="text-gray-600 mb-8">Please enter your details</p>
        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-1/3 transform -translate-y-1/2"
            >
              {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
            </button>
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-700 text-white py-2 rounded-md hover:bg-indigo-900 transition duration-300"
          >
            Log In
          </button>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          {loading && <div className="text-blue-500 mt-2">Logging in...</div>}
        </form>
      </div>
    </div>
  </div>
  );
};

export default Login;
