import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Sidebar from '../Components/Sidebar';
import NotificationDetails from '../Components/NotificationDetails';
import { getLoggedInUserCompany } from '../Services/DataServices';
import { NotificationsDD } from '../Components/NotificationsDD';

const Notification = () => {
  const email = localStorage.getItem('email'); // Adjust based on how you store email
  const [addAccess, setAddAccess] = useState(false);
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);

  useEffect(() => {
    getLoggedInUserCompany().then((d) => {
      setCompany(d);
    }).catch(e => console.log(e));
  }, []);  // Depend on refresh state

  return (
    <div>
      <div className='rounded-sm border border-stroke bg-white p-2 pt-3 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1'>
        <NotificationsDD company={null} />

      </div>
    </div>
  );
};

export default Notification;
