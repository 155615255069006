import axios from "./AxiosSetup";

export const updateCompanyDetails = async (companyId, editedCompany) => {
    // Retrieve the auth token from local storage
    const authToken = localStorage.getItem('token');
    editedCompany = {...editedCompany, applicationUsers:[]}

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Companies/${companyId}`; // Replace with your actual API endpoint
    return axios.put(apiUrl, editedCompany, config)
        .then(response => {
            console.log('Company updated successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error creating company:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
    
};


export const updateScripts = async (ScriptId, editedScript) => {
    console.log(editedScript)
    // Retrieve the auth token from local storage
    const authToken = localStorage.getItem('token');

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Script/${ScriptId}`; // Replace with your actual API endpoint
    return axios.put(apiUrl, editedScript, config)
        .then(response => {
            console.log('Script updated successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error updating company:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
    
};

export const UpdateExistingNotification = async(notificationData) =>{
    // Retrieve the auth token from local storage
    const authToken = localStorage.getItem('token');

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Notification/UpdateNotification`;  // Replace with your actual API endpoint

    // Make the POST request
    return axios.put(apiUrl, notificationData, config)
        .then(response => {
            console.log('Noti created successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error creating notification:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
}


export const updateDashboardDetails = async (companyid, newDashboard) =>{

    // Retrieve the auth token from local storage
    const authToken = localStorage.getItem('token');

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Dashboard/${companyid}`;  // Replace with your actual API endpoint

    // Make the POST request
    return axios.put(apiUrl, newDashboard, config)
        .then(response => {
            console.log('dashboard updated successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error dashbaord update:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
}


export const UpdateUserPassword = async (email, pwd) =>{
    // Retrieve the auth token from local storage
    const authToken = localStorage.getItem('token');

    const newCreds= {
        email:email,
        newPassword: pwd
    }

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Account/updateUserPassword`;  // Replace with your actual API endpoint

    // Make the POST request
    return axios.put(apiUrl, newCreds, config)
        .then(response => {
            console.log('dashboard updated successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error dashbaord update:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
}


export const ToggleNumberActivation = async(NotificationID) =>{
    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key

      const resp = await axios.put(`${process.env.REACT_APP_API_URL}/api/Notification/ToggleStatus/${NotificationID}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }
}

export const TurnALLONOFF = async(ScriptID, newStatus) =>{
    try{
        const token = localStorage.getItem('token'); // Replace 'token' with your actual token key

      const resp = await axios.put(`${process.env.REACT_APP_API_URL}/api/Notification/ChangeStatusForAll/${ScriptID}/${newStatus}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }})
         return resp.data;

    }
    catch(err)
    {
        throw "Error"
    }
}

export const updateUserDashboard = async (userEmail, newDashboardIDs) =>{

    // Retrieve the auth token from local storage
    const authToken = localStorage.getItem('token');

    // Set up the Axios configuration for the POST request
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`  // Assume the token is a Bearer token
        }
    };

    const Data ={
        UserEmail:userEmail,
        DashboardIDs:newDashboardIDs
    }

    // URL of the API endpoint
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/Dashboard/UpdateUserDashboardMultiple/`;  // Replace with your actual API endpoint

    // Make the POST request
    return axios.post(apiUrl,Data, config)
        .then(response => {
            console.log('dashboard updated successfully:', response.data);
            return response.data;  // Return the response data
        })
        .catch(error => {
            console.error('Error dashbaord update:', error.response ? error.response.data : error);
            throw error;  // Re-throw the error to be handled by the caller
        });
}