import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCompanyDetails } from '../Services/DataServices';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';
import { updateCompanyDetails } from '../Services/UpdateServices';
import ScriptDetails from '../Components/ScriptDetails';
import DashboardEdit from '../Components/DashboardEdit';
import ManagerDetails from '../Components/ManagerDetails';
import UserDetails from '../Components/UserDetails';
import { NotificationsDD } from '../Components/NotificationsDD';
import "../Styles/config.css";

const CompanyConfig = () => {
    const { companyId } = useParams();
    const [company, setCompany] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedCompany, setEditedCompany] = useState(null);

    useEffect(() => {
        fetchCompanyDetails(companyId).then(data => {
            setCompany(data);
            setEditedCompany({ ...data });  // Initialize editedCompany state with fetched data
        });
    }, [companyId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedCompany(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
        if (isEditing) {
            // Save the changes
            updateCompanyDetails(companyId, editedCompany).then(response => {
                console.log('Company updated successfully:', response.data);
                setCompany(editedCompany); // Update the main company state with the new details
            })
                .catch(error => {
                    console.error('Failed to update company:', error);
                });
        } else {
            // Reset any changes if cancelled
            setEditedCompany({ ...company });
        }
    };



    if (!company) return <div>Loading...</div>;

    return (
        <div>
                <div className="d-flex flex-column justify-content-center">
                <div className='m-2 rounded-sm border border-stroke bg-white px-3 py-3 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 x3:pb-1'>
                    <h1 className='text-xl font-bold bg-indigo-200 text-indigo-700 p-1'>Company Configuration: {editedCompany && editedCompany.companyName}</h1>
                    <p className='text-lg bg-indigo-200 text-indigo-700 p-1'>Here you can view or edit the configurations for this company.</p>
                    <div className='flex gap-2'>
                    <button className="bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded flex items-center mt-3 mb-3" onClick={toggleEdit}>
                        {isEditing ? 'Save Changes' : 'Edit'}
                    </button>
                    {isEditing && (
                        <button className="bg-gray-700 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded flex items-center mt-3 mb-3" onClick={() => {
                            setIsEditing(false);
                            setEditedCompany({ ...company }); // Revert changes
                        }}>
                            Cancel
                        </button>
                    )}

                    </div>
                   

                    <form>
                        <div className="mb-2">
                            <label htmlFor="companyName" className="block text-gray-700 text-sm font-bold mb-2">Company Name</label>
                            <input
                                type="text"
                                className="shadow-default appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="companyName"
                                name="companyName"
                                value={editedCompany.companyName}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="uniqueClientId" className="block text-gray-700 text-sm font-bold mb-2">Unique Client ID</label>
                            <input
                                type="text"
                                className="shadow-default appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="uniqueClientId"
                                name="uniqueClientID"
                                value={editedCompany.uniqueClientID}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="pan" className="block text-gray-700 text-sm font-bold mb-2">PAN</label>
                            <input
                                type="text"
                                className="shadow-default appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="pan"
                                name="pan"
                                value={editedCompany.pan}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                        </div>
                        {/* Add more fields as needed */}
                    </form>
                </div>
                <div className='m-2 rounded-sm border border-stroke bg-white px-3 py-3 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 x3:pb-1'>
                    <DashboardEdit companyId={companyId} />
                </div>
                <div className='m-2 rounded-sm border border-stroke bg-white px-3 py-3 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 x3:pb-1'>
                    <ManagerDetails company={company} />
                </div>
                <div className='m-2 rounded-sm border border-stroke bg-white px-3 py-3 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 x3:pb-1'>
                    <UserDetails company={company} />
                </div>
                <div className='m-2 rounded-sm border border-stroke bg-white px-3 py-3 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 x3:pb-1'>
                    <ScriptDetails company={company} />
                </div>
                <div className='m-2 rounded-sm border border-stroke bg-white px-3 py-3 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 x3:pb-1'>
                    <NotificationsDD company={company} />
                </div>

            </div>
                </div>
    );
};

export default CompanyConfig;
