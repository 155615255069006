import React from 'react';
import { useNavigate } from 'react-router-dom';

const CompanyCard = ({ company }) => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/company/${company.companyID}`);
    };

    return (
        <div className="bg-white border border-stroke shadow-default rounded-lg overflow-hidden max-w-sm m-2" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
        <div className="p-6">   
          <h2 className="text-xl font-semibold text-gray-800 mb-2">{company.companyName}</h2>
          <p className="text-gray-600 mb-4">Unique Client ID: {company.uniqueClientID}</p>
          <p className="text-gray-600 mb-4">PAN: {company.pan}</p>
          <button className="w-full bg-indigo-700 text-white font-bold py-2 px-4 rounded hover:bg-indigo-600 transition duration-300">
            Select
          </button>
        </div>
      </div>
    );
};

export default CompanyCard;
