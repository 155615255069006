import React, { useState, useEffect } from 'react';
import { fetchDashboardDetails } from '../Services/DataServices';
import { createDashboard } from '../Services/CreateServices';
import { updateDashboardDetails } from '../Services/UpdateServices'; // Assume createDashboard is implemented
import AddDashboard from './AddDashboard'; // Import the AddDashboard component
import { deleteDashboard } from '../Services/DeleteServices';
import { RefreshCw, UserPlus, PenSquare, Trash2, RotateCw } from 'lucide-react';


function DashboardEdit({ companyId }) {
    const [dashboards, setDashboards] = useState([]);
    const [editDashboardId, setEditDashboardId] = useState(null);
    const [editedDashboards, setEditedDashboards] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        fetchDashboardDetails(companyId).then(data => {
            setDashboards(data);
            const dashboardEdits = data.reduce((acc, dashboard) => {
                acc[dashboard.dashboardID] = { ...dashboard };
                return acc;
            }, {});
            setEditedDashboards(dashboardEdits);

        });
    }, [companyId, refresh]);

    const handleInputChange = (e, dashboardId) => {
        const { name, value } = e.target;
        setEditedDashboards(prev => ({
            ...prev,
            [dashboardId]: { ...prev[dashboardId], [name]: value }
        }));
    };

    const handleEdit = (dashboardId) => {
        setEditDashboardId(dashboardId);
        console.log(editedDashboards);
        setIsEditing(true);
    };

    const handleDelete = (dashboardId) => {
        deleteDashboard(dashboardId).then(r => setRefresh(!refresh)).catch(e => console.log(e))
    };

    const handleSave = (dashboardId) => {
        updateDashboardDetails(companyId, editedDashboards[dashboardId])
            .then(response => {
                console.log('Dashboard updated successfully:', response.data);
                // setDashboards(dashboards.map(dashboard => dashboard.dashboardId === dashboardId ? { ...editedDashboards[dashboardId] } : dashboard));
                setEditDashboardId(null);
                setIsEditing(false);
                setRefresh(!refresh); // Trigger refresh
            })
            .catch(error => {
                console.error('Failed to update dashboard:', error);
            });
    };

    const handleCancel = (dashboardId) => {
        const originalDashboard = dashboards.find(dashboard => dashboard.dashboardId === dashboardId);
        // setEditedDashboards(dashboards);
        setEditDashboardId(null);
        setIsEditing(false);
    };

    const handleSaveDashboard = (dashboardData) => {
        console.log("Saving dashboard:", dashboardData);
        createDashboard(dashboardData)
            .then(response => {
                console.log('Dashboard created successfully:', response.data);
                setRefresh(!refresh);  // Toggle the refresh state to trigger re-fetching of dashboards
                setModalShow(false);   // Close the modal on save
            })
            .catch(error => console.error('Failed to create dashboard:', error));
    };

    return (
        <div>
            <h1 className='text-xl font-bold bg-yellow-200 text-yellow-700 p-1'>Dashboard Configuration</h1>
            <p className='text-lg bg-yellow-200 text-yellow-700 p-1'>Here you can view or edit the configurations for dashboards.</p>
            <button className="bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded flex items-center mt-3 mb-3" onClick={() => setModalShow(true)}>Add Dashboard</button>
            <AddDashboard
                show={modalShow}
                onClose={() => setModalShow(false)}
                onSave={handleSaveDashboard}
                company={{ companyID: companyId, companyName: 'Company Name' }} // Replace 'Company Name' with actual company name if available
            />

            <div className="overflow-x-auto">
                <table className="w-full table-auto border-collapse">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2 text-left">Dashboard Name</th>
                            <th className="px-4 py-2 text-left">Dashboard URL</th>
                            <th className="px-4 py-2 text-left">Comments</th>
                            <th className="px-4 py-2 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dashboards.map(dashboard => (
                            <tr key={dashboard.dashboardID} className="border-b hover:bg-gray-100">
                                {editDashboardId === dashboard.dashboardID ? (
                                    <>
                                        <td className="px-4 py-3">
                                            <input
                                                type="text"
                                                name="dashboardName"
                                                value={editedDashboards[dashboard.dashboardID].dashboardName}
                                                onChange={e => handleInputChange(e, dashboard.dashboardID)}
                                                className="w-full p-2 border rounded"
                                            />
                                        </td>
                                        <td className="px-4 py-3">
                                            <input
                                                type="text"
                                                name="dashboardURL"
                                                value={editedDashboards[dashboard.dashboardID].dashboardURL}
                                                onChange={e => handleInputChange(e, dashboard.dashboardID)}
                                                className="w-full p-2 border rounded"
                                            />
                                        </td>
                                        <td className="px-4 py-3">
                                            <input
                                                type="text"
                                                name="dashboardComments"
                                                value={editedDashboards[dashboard.dashboardID].dashboardComments}
                                                onChange={e => handleInputChange(e, dashboard.dashboardID)}
                                                className="w-full p-2 border rounded"
                                            />
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td className="px-4 py-3">{dashboard.dashboardName}</td>
                                        <td className="px-4 py-3">{dashboard.dashboardURL}</td>
                                        <td className="px-4 py-3">{dashboard.dashboardComments}</td>
                                    </>
                                )}
                                <td className="px-4 py-3">
                                    {editDashboardId === dashboard.dashboardID ? (
                                        <div className="flex space-x-2">
                                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-2 rounded" onClick={() => handleSave(dashboard.dashboardID)}>Save</button>
                                            <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-2 rounded" onClick={() => handleCancel(dashboard.dashboardID)}>Cancel</button>
                                        </div>
                                    ) : (
                                        <div className="flex space-x-2">
                                            <button className="bg-indigo-700 hover:bg-indigo-900 text-white font-bold p-2 rounded" onClick={() => handleEdit(dashboard.dashboardID)}>
                                                <PenSquare className="w-4 h-4" />
                                            </button>
                                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold p-2 rounded" onClick={() => handleDelete(dashboard.dashboardID)}>
                                                <Trash2 className="w-4 h-4" />
                                            </button>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default DashboardEdit;
