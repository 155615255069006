import React, { useState, useEffect } from 'react';
import { Bell, Search, Home, Users, Briefcase, Calendar, FileText, BarChart2, Settings, Menu, X } from 'lucide-react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import UserAvatar from "../Assets/Images/useravatar.png";

const Layout = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false); // State for sidebar visibility
    const email = localStorage.getItem("email")

    const logoutUser = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error("No token found");
            return;
        }

        const confirmation = window.confirm("Are you sure you want to log out?");
        if (!confirmation) {
            return;
        }

        try {
            localStorage.clear();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed', error);
        }
    }

    const routes = localStorage.getItem("routes");
    var accessAllowed = [];
    if (routes) {
        accessAllowed = routes.split(",");
    }

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 1024);
        };
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const handleLinkClick = () => {
        if (isMobile) {
            setIsMobileMenuOpen(false);
        }
    };

    const NavItem = ({ Icon, text, title, isActive = false }) => (
        <li>
            {accessAllowed.includes(title) && <Link to={`/${text}`} className={`flex items-center px-2 py-2 ${isActive ? 'bg-gray-800' : 'hover:bg-gray-800'
                } rounded transition-colors duration-200`} onClick={handleLinkClick}>
                <Icon className="w-5 h-5" />
                <span className="ml-3 text-white">{text}</span>
            </Link>}
        </li>
    );

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar */}
            <aside
                className={`${isMobile && !isMobileMenuOpen ? '-translate-x-full' : 'translate-x-0'
                    } fixed lg:relative z-30 w-64 h-full bg-gray-900 text-white transition-transform duration-300 ease-in-out`}
            >
                <div className="flex flex-col h-full p-4">
                    <div className="mb-8 flex justify-between items-center">
                        <div className="w-9 h-9 bg-indigo-700 rounded-full font-fold text-xl">
                            <span className='font-bold'>TechCore.</span>
                        </div>
                        {isMobile && (
                            <button
                                onClick={() => setIsMobileMenuOpen(false)}
                                className="text-white hover:text-gray-300"
                            >
                                <X className="w-6 h-6" />
                            </button>
                        )}
                    </div>
                    <nav className="flex-grow">
                        <ul className="space-y-2">
                            <NavItem Icon={Home} text="Dashboard" isActive title="Dashboard" />
                            <NavItem Icon={Users} text="Users" title="Users" />
                            <NavItem Icon={Briefcase} text="Notifications" title="Notification" />
                            <NavItem Icon={Calendar} text="Admin" title="Manage" />
                            <a onClick={logoutUser} href="#" className={`flex items-center px-2 py-2 rounded transition-colors duration-200`}>
                                <FileText className="w-5 h-5" />
                                <span className="ml-3 text-white">Logout</span>
                            </a>
                        </ul>
                    </nav>
                    <div className="mt-auto">
                        <NavItem Icon={Settings} text="Settings" />
                    </div>
                </div>
            </aside>

            {/* Main content */}
            <main className="flex-1 flex flex-col overflow-hidden">
                <header className="bg-white shadow-sm z-20">
                    <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
                        <div className="flex items-center">
                            {isMobile && (
                                <button
                                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                                    className="mr-4 text-gray-500 hover:text-gray-700"
                                >
                                    <Menu className="w-6 h-6" />
                                </button>
                            )}
                            <div className="relative hidden lg:block ">
                                <input type="text" placeholder="Search..." className="pl-10 pr-4 py-2 border rounded-full w-64" />
                                <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
                            </div>
                        </div>
                        <div className="flex items-center space-x-3">
                            <Bell className="text-gray-500" />
                            <img src={UserAvatar} alt="User avatar" className="w-8 h-8 rounded-full" />
                            <span className="sm:inline">{email}</span>
                        </div>
                    </div>
                </header>
                <div className="flex-1 overflow-auto">
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        {/* Main content area */}
                        <Outlet />
                    </div>
                </div>
            </main>

            {/* Overlay for mobile menu */}
            {isMobile && isMobileMenuOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-20"
                    onClick={() => setIsMobileMenuOpen(false)}
                ></div>
            )}
        </div>
    );
};

export default Layout;