import React, { useEffect, useState } from 'react';
import { RefreshCw, UserPlus, PenSquare, Trash2, RotateCw } from 'lucide-react';
import ConfirmModal from './ConfirmModal';
import AddUser from './AddUser';
import ResetPasswordModal from './ResetPasswordModal';
import AppToast from './AppToast';
import { fetchActiveCompanyUsers, getLoggedInUserCompany, fetchDashboardDetails } from '../Services/DataServices';
import { CreateUser } from '../Services/CreateServices';
import { UpdateUserPassword, updateUserDashboard } from '../Services/UpdateServices';
import { deleteUser } from '../Services/DeleteServices';
import Select from "react-select";

const UserDetails = ({ company: initialCompany }) => {
    const [users, setUsers] = useState([]);
    const [editUserId, setEditUserId] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalAddShow, setModalAddShow] = useState(false);
    const [originalData, setOriginalData] = useState({});
    const [currentUser, setCurrentUser] = useState(null);
    const [passwordVisibility, setPasswordVisibility] = useState({});
    const [company, setCompany] = useState(null);
    const [modalResetPasswordShow, setModalResetPasswordShow] = useState(false);
    const [companyDashboards, setCompanyDashboards] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [toastType, setToastType] = useState("success");
    const [editUserDashboard, setEditUserDashboard] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const [dashboardOptions, setDashboardOptions] = useState([]);

    useEffect(() => {
        setLoading(true); // Start loading
        if (initialCompany) {
            setCompany(initialCompany);
            fetchActiveCompanyUsers(initialCompany.companyID)
                .then((d) => {
                    setUsers(d)
                    console.log(d)
                })
                .catch(e => console.log(e));
            fetchDashboardDetails(initialCompany.companyID)
                .then((d) => {
                    setCompanyDashboards(d);
                    const resp = d;
                    setDashboardOptions(resp.map(dash=>({
                        value: dash.dashboardID,
                        label: dash.dashboardName
                    })))

                }
            )
                .catch(e => console.log(e))
                .finally(() => setLoading(false)); // Stop loading
        } else {
            getLoggedInUserCompany().then((r) => {
                setCompany(r);
                fetchActiveCompanyUsers(r.companyID)
                    .then(d => setUsers(d))
                    .catch(e => console.log(e));
                    fetchDashboardDetails(r.companyID)
                    .then((d) => {
                        setCompanyDashboards(d);
                        const resp = d;
                        setDashboardOptions(resp.map(dash=>({
                            value: dash.dashboardID,
                            label: dash.dashboardName
                        })))
    
                    }
                )
                    .catch(e => console.log(e))
                    .finally(() => setLoading(false)); // Stop loading
            }).catch(e => console.log(e));
        }
    }, [refresh]);

    const handleDelete = (user) => {
        setModalShow(true);
        setCurrentUser(user);
    };

    const confirmDelete = () => {
        setUsers(users.filter(u => u.id !== currentUser.id));
        deleteUser(currentUser.email).then(r => console.log(r)).catch(e => console.log(e));
        setModalShow(false);
        setRefresh(!refresh);
    };

    const handleEdit = (user) => {
        console.log(user)
        setEditUserId(user.email);
        setOriginalData({ ...user });
        // setEditUserDashboard(user.UserDashboardName); // Set the initial dashboard name for edit
    };

    const handleChange = (e, id) => {
        const { name, value } = e.target;
        setUsers(users.map(user => user.id === id ? { ...user, [name]: value } : user));
    };


    const handleDashboardChange = (selectedOptions) => {
        // selectedOptions will be an array of selected values
        setEditUserDashboard(selectedOptions || []);
    };

    const handleSave = () => {
        const updatedUser = users.find(user => user.email === editUserId);
        if (!editUserDashboard.length) {
            return;
        }
        const selectedDashboard = companyDashboards.find(d => d.dashboardID.toString() === editUserDashboard);

        const selectedDashboardIds = editUserDashboard.map(option => option.value);
        console.log(selectedDashboardIds);

        // console.log(updatedUser.email);
        updateUserDashboard(updatedUser.email, selectedDashboardIds).then(r => {
            // console.log(r);
            setRefresh(!refresh);
            setShowToast(true);
            setToastMsg("User dashboard updated successfully!");
            setToastType("success");
        }).catch(e => {
            setShowToast(true);
            setToastMsg(e.message);
            setToastType("danger");
        });
        setEditUserId(null);
    };

    const handleCancel = () => {
        setUsers(users.map(user => user.id === editUserId ? { ...originalData } : user));
        setEditUserId(null);
        setEditUserDashboard(null);
    };

    const handleAddNewUser = (newUserData) => {
        CreateUser(company.companyID, newUserData)
            .then((r) => {
                console.log(r);
                setRefresh(!refresh);
                setShowToast(true);
                setToastMsg("New user added successfully!");
                setToastType("success");
            }).catch((e) => {
                setToastMsg(e.message);
                setToastType("danger");
                setShowToast(true);
            });
        setModalAddShow(false);
    };

    const handleResetPassword = (user) => {
        setCurrentUser(user);
        setModalResetPasswordShow(true);
    };

    const saveNewPassword = (email, newPassword) => {
        UpdateUserPassword(email, newPassword).then(r => {
            console.log(r);
            setShowToast(true);
            setToastMsg("Password updated successfully!");
            setToastType("success");
        }).catch(e => {
            setShowToast(true);
            setToastMsg(e.message);
            setToastType("danger");
        });
        setModalResetPasswordShow(false);
    };

    const refreshComp = () => {
        setRefresh(!refresh);
    }



    return (
        <div>
                <h2 className='text-xl font-bold bg-blue-200 text-blue-700 p-1'>User Accounts</h2>
                <p className='text-lg font-bold bg-blue-200 text-blue-700 p-1'>Update User Accounts here</p>
                <div className="flex items-center mb-4">
                    <button className="bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded flex items-center mt-3 mb-3" onClick={() => setModalAddShow(true)}>
                        Add New User <UserPlus className="ml-2 w-5 h-5" />
                    </button>
                    <span className="ml-auto cursor-pointer text-blue-500 hover:text-blue-700 flex items-center" onClick={refreshComp}>
                        Refresh <RefreshCw className="ml-1 w-5 h-5" />
                    </span>
                </div>
                {loading ? (
                    <div className="flex justify-center">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="w-full table-auto border-collapse">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="px-4 py-2 text-left">First Name</th>
                                    <th className="px-4 py-2 text-left">Last Name</th>
                                    <th className="px-4 py-2 text-left">Email</th>
                                    <th className="px-4 py-2 text-left">Dashboard</th>
                                    <th className="px-4 py-2 text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => (
                                    <tr key={user.id} className="border-b hover:bg-gray-100">
                                        <td className="px-4 py-3">{user.firstName}</td>
                                        <td className="px-4 py-3">{user.lastName}</td>
                                        <td className="px-4 py-3">{user.email}</td>
                                        <td className="px-4 py-3">
                                            {editUserId === user.email ? (
                                                <Select
                                                    isMulti
                                                    className="w-full p-2 border rounded"
                                                    classNamePrefix="select"
                                                    value={editUserDashboard}
                                                    onChange={handleDashboardChange}
                                                    options={dashboardOptions}
                                                    placeholder="Select Dashboards"
                                                />
                                                // <select
                                                //     className="w-full p-2 border rounded"
                                                //     value={editUserDashboard || ''}
                                                //     onChange={handleDashboardChange}
                                                // >
                                                //     <option value="">Select Dashboard</option>
                                                //     {companyDashboards.map(dash => (
                                                //         <option key={dash.dashboardID} value={dash.dashboardID}>
                                                //             {dash.dashboardName}
                                                //         </option>
                                                //     ))}
                                                // </select>
                                            ) : (
                                                <div className="flex items-center">
                                                    <span>{user.userDashboardName}</span>
                                                    <button
                                                        className="ml-2 bg-indigo-700 hover:bg-indigo-500 text-white font-bold p-2 rounded"
                                                        onClick={() => handleEdit(user)}
                                                    >
                                                        <PenSquare className="w-4 h-4" />
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                        <td className="px-4 py-3">
                                            {editUserId === user.email ? (
                                                <div className="flex space-x-2">
                                                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-2 rounded" onClick={handleSave}>Save</button>
                                                    <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-2 rounded" onClick={handleCancel}>Cancel</button>
                                                </div>
                                            ) : (
                                                <div className="flex space-x-2">
                                                    <button className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded flex items-center" onClick={() => handleResetPassword(user)}>
                                                        Reset Password <RotateCw className="ml-1 w-4 h-4" />
                                                    </button>
                                                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold p-2 rounded" onClick={() => handleDelete(user)}>
                                                        <Trash2 className="w-4 h-4" />
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {/* Keep all modal components and AppToast as they were */}
                {modalShow && (
                    <ConfirmModal
                        show={modalShow}
                        onClose={() => setModalShow(false)}
                        onCancel={() => setModalShow(false)}
                        onConfirm={confirmDelete}
                        title="Confirm Deletion"
                    >
                        Are you sure you want to delete {currentUser?.firstName} {currentUser?.lastName}?
                    </ConfirmModal>
                )}
                {modalAddShow && (
                    <AddUser show={modalAddShow} onClose={() => setModalAddShow(false)} onSave={handleAddNewUser} />
                )}
                {modalResetPasswordShow && (
                    <ResetPasswordModal
                        show={modalResetPasswordShow}
                        onClose={() => setModalResetPasswordShow(false)}
                        onSave={saveNewPassword}
                        onCancel={() => setModalResetPasswordShow(false)}
                        user={currentUser}
                    />
                )}
                <AppToast
                    message={toastMsg}
                    type={toastType}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                />
        </div>
    );
};

export default UserDetails;