import React, { useEffect, useState } from 'react';
import ConfirmModal from './ConfirmModal';
import AddUser from './AddUser';
import { Eye, EyeSlash, Trash, ArrowClockwise, PersonAdd } from 'react-bootstrap-icons';
import { fetchCompanyManager } from '../Services/DataServices';
import { CreateManager } from '../Services/CreateServices';
import ResetPasswordModal from './ResetPasswordModal';
import { UpdateUserPassword } from '../Services/UpdateServices';
import AppToast from '../Components/AppToast';
import { deleteUser } from '../Services/DeleteServices';
import { Trash2, RotateCw, Save, X } from 'lucide-react';


const ManagerDetails = ({company}) => {
    const [users, setUsers] = useState([]);
    const [editUserId, setEditUserId] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalAddShow, setModalAddShow] = useState(false);
    const [originalData, setOriginalData] = useState({}); // Store original data in case of cancel
    const [currentUser, setCurrentUser] = useState(null);
    const [modalResetPasswordShow, setModalResetPasswordShow] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [toastType, setToastType] = useState("success"); 

    useEffect(() => {
        fetchCompanyManager(company.companyID).then(d=> setUsers(d)).catch(e=>console.log(e));
    }, [refresh]);  // Depend on refresh state


    const handleDelete = (user) => {
        setModalShow(true);
        setCurrentUser(user);
    };

    const confirmDelete = () => {
        setUsers(users.filter(u => u.id !== currentUser.id));
        deleteUser(currentUser.email).then(r => console.log(r)).catch(e => console.log(e));
        setModalShow(false);
        setRefresh(!refresh);
    };

    const handleEdit = (user) => {
        setEditUserId(user.id);
        setOriginalData({ ...user });  // Save original data
    };

    const handleChange = (e, id) => {
        const { name, value } = e.target;
        setUsers(users.map(user => user.id === id ? { ...user, [name]: value } : user));
    };

    const handleSave = () => {
        setEditUserId(null); // Save and exit edit mode
    };

    const handleCancel = () => {
        setUsers(users.map(user => user.id === editUserId ? { ...originalData } : user)); // Revert changes
        setEditUserId(null); // Exit edit mode
    };

    const handleAddNewUser = (newUserData) => {
        console.log(newUserData)
        CreateManager(company.companyID,newUserData)
        .then((r)=>{
            console.log(r);
            setRefresh(!refresh);
        }).catch((e)=>{
            setToastMsg(e);
            setToastType("danger");
            setShowToast(true);
        })
        setModalAddShow(false);
    };

    const handleResetPassword = (user) => {
        setCurrentUser(user);
        setModalResetPasswordShow(true);
    };

    const saveNewPassword = (email, newPassword) => {
        UpdateUserPassword(email, newPassword).then(r=>console.log(r)).catch(e=>console.log(e))
        // Here you would call the service to update the password
        console.log(`Password for ${email} updated to ${newPassword}`);
        setModalResetPasswordShow(false);
    };

    return (
        <div>
            <h2 className='text-xl font-bold bg-red-200 text-red-700 p-1'>Manager Accounts</h2>
            <p className='text-lg font-bold bg-red-200 text-red-700 p-1'>Update Manager Accounts here</p>
            <button className="bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded flex items-center mt-3 mb-3" onClick={() => setModalAddShow(true)}>Add New Manager <PersonAdd /></button>
            <div className="overflow-x-auto">
            <table className="w-full table-auto border-collapse">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-4 py-2 text-left">First Name</th>
                        <th className="px-4 py-2 text-left">Last Name</th>
                        <th className="px-4 py-2 text-left">Email</th>
                        <th className="px-4 py-2 text-left">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id} className="border-b hover:bg-gray-100">
                            {editUserId === user.id ? (
                                <>
                                    <td className="px-4 py-3">
                                        <input 
                                            type="text" 
                                            name="firstName" 
                                            value={user.firstName} 
                                            onChange={e => handleChange(e, user.id)} 
                                            className="w-full p-2 border rounded"
                                        />
                                    </td>
                                    <td className="px-4 py-3">
                                        <input 
                                            type="text" 
                                            name="lastName" 
                                            value={user.lastName} 
                                            onChange={e => handleChange(e, user.id)} 
                                            className="w-full p-2 border rounded"
                                        />
                                    </td>
                                    <td className="px-4 py-3">
                                        <input 
                                            type="email" 
                                            name="email" 
                                            value={user.email} 
                                            onChange={e => handleChange(e, user.id)} 
                                            className="w-full p-2 border rounded"
                                        />
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td className="px-4 py-3">{user.firstName}</td>
                                    <td className="px-4 py-3">{user.lastName}</td>
                                    <td className="px-4 py-3">{user.email}</td>
                                </>
                            )}
                            <td className="px-4 py-3">
                                {editUserId === user.id ? (
                                    <div className="flex space-x-2">
                                        <button 
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
                                            onClick={handleSave}
                                        >
                                            <Save className="mr-2 h-4 w-4" /> Save
                                        </button>
                                        <button 
                                            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded flex items-center"
                                            onClick={handleCancel}
                                        >
                                            <X className="mr-2 h-4 w-4" /> Cancel
                                        </button>
                                    </div>
                                ) : (
                                    <div className="flex space-x-2">
                                        <button 
                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center"
                                            onClick={() => handleDelete(user)}
                                        >
                                            <Trash2 className="mr-2 h-4 w-4" /> Delete
                                        </button>
                                        <button 
                                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
                                            onClick={() => handleResetPassword(user)}
                                        >
                                            <RotateCw className="mr-2 h-4 w-4" /> Reset Password
                                        </button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
            {modalShow && (
                <ConfirmModal
                    show={modalShow}
                    onClose={() => setModalShow(false)}
                    onCancel={()=>setModalShow(false)}
                    onConfirm={confirmDelete}
                    title="Confirm Deletion"
                >
                    Are you sure you want to delete {currentUser?.firstName} {currentUser?.lastName}?
                </ConfirmModal>
            )}
            {modalAddShow && (
                <AddUser show={modalAddShow} onClose={() => setModalAddShow(false)} onSave={handleAddNewUser} />
            )}
             {modalResetPasswordShow && (
                <ResetPasswordModal
                    show={modalResetPasswordShow}
                    onClose={() => setModalResetPasswordShow(false)}
                    onSave={saveNewPassword}
                    onCancel={() => setModalResetPasswordShow(false)}
                    user={currentUser}
                />
            )}
            <AppToast 
             message={toastMsg}
             type={toastType}
             show={showToast}
             onClose={() => setShowToast(false)}
            />
        </div>
    );
};

export default ManagerDetails;
